import React from 'react'

//old earnings item: {`$${numberWithCommas(props.earnings.reduce((a,b) => a + b, 0))}`}
const ownerEarningsItem = (props) => {


    function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   }

    return (
        <tr>
            <td>{props.owner}</td>
            <td></td>
            <td>{props.wins}</td>
            <td>{props.mcs}</td>
        </tr>
    )
}

export default ownerEarningsItem;