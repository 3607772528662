import React from 'react'
import Participant from '../Participant/Participant'
import Table from 'react-bootstrap/Table';
import EventName from '../EventName/EventName'

//sort on player position without the 'T'


const participantList = (props) => {
    
    return (
      <>
        <EventName eventName={props.eventName}/>
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                  {/* <th>OWGR</th> */}
                  <th>Position</th>
                  <th>Name</th>
                  <th>Overall</th>
                  <th>Today</th>
                  <th>Thru</th>
                  <th>Owner</th>
                </tr>
              </thead>
              <tbody>
                {props.participants.sort((a, b) => (a.sortPosition > b.sortPosition) ? 1 : -1).map((participant, index) =>
                    <Participant key={index}
                        position= {participant.position}
                        name= {participant.fullName}
                        overall= {participant.overallPar}
                        today= {participant.todayPar}
                        thru= {participant.thruHole}
                        owner= {participant.playerOwner}
                        owgr={participant.owgr}
                        displayAll = {props.displayAll}
                        drafted = {participant.drafted}
                    />
                    )}
              </tbody>
            </Table>
        </>
    )
}

export default participantList;
