import React from 'react'
import './Participant.css'


const participant = (props) => {
    return (
        <tr className={!props.displayAll && (props.position === "T1" || props.position === "1") ? "table-success" : 
        props.drafted==="x" && props.displayAll ? "table-info" : null}>
            {/* <td>{props.owgr}</td> */}
            <td>{props.position === "T999" ? "MC" : props.position}</td>
            <td>{props.name}</td>
            <td>{props.overall === 0 ? "E" : props.overall}</td>
            <td>{props.today === 0 ? "E" : props.today}</td>
            <td>{props.thru}</td>
            <td>{props.owner}</td>
        </tr>
    )
}

export default participant;