import React, { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import ParticipantList from './components/ParticipantList/ParticipantList'
import EventList from './components/EventList/EventList'
import OwnerEarnings from './components/OwnerEarnings/OwnerEarnings'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import DropdownButton from 'react-bootstrap/DropdownButton'

import './App.css';


function App() {

  const [participantState, setParticipantState] = useState( {
    participants: [] 
  });

  const [tournamentState, setTournamentState] = useState('18499');
  const [eventName, setEventName] = useState('Murfield II');
  const [displayAll, setDisplayAll] = useState(false);
  const draftedPlayers = participantState.participants.filter(player => player.drafted==='x')
  const [ownerEarnings, setOwnerEarnings] = useState({
    ownerEarnings: []
  });
  const [events, setEvents] = useState ({
    events: []
  })

  const showDrafted = () => setDisplayAll(false);
  const showFullBoard = () => setDisplayAll(true);
 
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     async function fetchData() {
  //       const res = await fetch(`https://2f22njp2kj.execute-api.us-east-1.amazonaws.com/dev/api/getLeaderboard/${tournamentState}`);
  //       res
  //         .json()
  //         .then(res => setParticipantState({participants: res}));
  //     }
  
  //     fetchData();
  //     alert("Leaderboard Updated")
  //   }, 10000);
  //   return () => clearInterval(interval);
  // }, []);


  
  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`https://2f22njp2kj.execute-api.us-east-1.amazonaws.com/dev/api/getLeaderboard/${tournamentState}`);
      res
        .json()
        .then(res => setParticipantState({participants: res}));
    }

    fetchData();
    
  },[tournamentState]);

  useEffect(() => {
    async function fetchEventName() {
      const res = await fetch(`https://2f22njp2kj.execute-api.us-east-1.amazonaws.com/dev/api/getEventName/${tournamentState}`);
      res
        .json()
        .then(res => setEventName(res[0]['eventName']));

    }

    fetchEventName();
    
  },[tournamentState]);

  useEffect(() => {
    async function fetchEarnings() {
      const res = await fetch("https://2f22njp2kj.execute-api.us-east-1.amazonaws.com/dev/api/getOwnerEarnings2");
      res
        .json()
        .then(res => setOwnerEarnings({ownerEarnings: res}));
    }
    fetchEarnings();
  },[]);

  useEffect(() => {
    async function fetchEvents() {
      const res = await fetch("https://2f22njp2kj.execute-api.us-east-1.amazonaws.com/dev/api/getEvents");
      res
        .json()
        .then(res => setEvents({events: res}));
    }
    fetchEvents();
  }, []);

  return (
    <div className="App">
      <Container>
        <Navbar bg="light" variant="light">
          <Navbar.Brand>
          Golf Draft
          </Navbar.Brand>
          <Nav className="mr-auto">

          </Nav>
        </Navbar>
        
      </Container>
      <br />
      <Container>
        <Row>
            <Col>
                <ParticipantList participants={displayAll ? participantState.participants : draftedPlayers}
                displayAll={displayAll} eventName={eventName}
                />
            </Col>
            <Col md="auto">
                <div>
                <Button onClick={showFullBoard} variant="info" block>Full Leaderboard</Button>
                <Button onClick={showDrafted} variant="info" block>Drafted Players</Button>
                <hr />
                <DropdownButton onSelect={function(evt){setTournamentState(evt)}} 
                                id="dropdown-basic-button" 
                                title="Select Tournament" 
                                variant="info" >
                  <EventList events = {events.events} />
                </DropdownButton>
                <br />
                <OwnerEarnings ownerEarnings={ownerEarnings.ownerEarnings}/>
                </div>     
            </Col>
        </Row>
        </Container>
        
    </div>
  );
}

export default App;

