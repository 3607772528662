import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'

const eventList = (props) => {

    return (
        props.events.sort((a, b) => (a.eventId > b.eventId) ? -1 : 1).map((event, index) =>
        <Dropdown.Item key={index} eventKey={event.eventId}>{event.eventName}</Dropdown.Item>
    )
    )

}

export default eventList;