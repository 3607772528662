import React from 'react'
import Table from 'react-bootstrap/Table';
import OwnerEarningsItem from '../OwnerEarningsItem/OwnerEarningsItem'


const ownerEarnings = (props) => {
    
    return (
      <>
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                  <th>Owner</th>
                  <th>Season Earnings</th>
                  <th>Wins</th>
                  <th>MCs</th>
                </tr>
              </thead>
              <tbody>
                {props.ownerEarnings.sort((a, b) => (a.earnings.reduce((a,b) => a + b, 0) > b.earnings.reduce((a,b) => a + b, 0)) ? -1 : 1).map((owner, index) =>
                    <OwnerEarningsItem key={index} 
                        owner= {owner.owner}
                        earnings= {owner.earnings}
                        //earnings="trash data"
                        wins={owner.wins}
                        mcs={owner.mcs}
                     />
                    )}
              </tbody>
            </Table>
        </>
    )
}

export default ownerEarnings;
